import { useEffect, useState } from "react";
import Head from "next/head";
import Image from "next/image";

// Import required MUI layout/components
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuUnstyled from "@mui/base/MenuUnstyled";
import div from "@mui/base/MenuItemUnstyled";
import Link from "@mui/material/Link";

import mainTheme from "./mainTheme";
import navStyles from "../styles/Navigation.module.css";

const MainHeader = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const handleOpen = (event) => {
    setActiveMenu(event.target.id);
  };
  const handleClose = () => {
    setActiveMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.id != activeMenu) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeMenu]);

  return (
    <ThemeProvider theme={mainTheme}>
      <div style={mainTheme.custom.navigationTop}>
        <Container maxWidth="lg">
          <div className={navStyles.navigationTopContainer}>
            <div className={navStyles.navigationTopLink}>
              <Link href="/demo" style={{ textDecoration: "none", color: mainTheme.palette.secondary.main }}>
                Request Demo
              </Link>
            </div>
            <div>
              <Link href="/blog">
                <Image src="/magGlass.svg" alt="Search Icon" width={24} height={24} />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div style={mainTheme.custom.navigationBottom}>
        <Container maxWidth="lg">
          <div className={navStyles.navigationMobileNav}>
            <div className={navStyles.navigationBottomContainer}>
              <div className={navStyles.navigationMainLogo}>
                <div style={{ maxWidth: "200px" }}>
                  <Link href="/">
                    <Image src="/headerLogo.svg" alt="Elm Street Logo" width={197} height={45} layout="responsive" />
                  </Link>
                </div>
              </div>
              <div>
                <div className={`${activeMenu == "mobilenav" ? navStyles.dropdownActive : navStyles.dropdown}`}>
                  <Image
                    src="/mobileMenu.svg"
                    alt="Mobile Menu Icon"
                    width={24}
                    height={24}
                    onClick={handleOpen}
                    onMouseOver={handleOpen}
                    id="mobilenav"
                  />
                  <div className={navStyles.dropdownMenu} onMouseLeave={handleClose}>
                    <div
                      style={{ float: "right", color: mainTheme.palette.secondary.main, cursor: "pointer" }}
                      onClick={handleClose}
                    >
                      X
                    </div>
                    <div className={navStyles.dropdownMobileContainer}>
                      <div className={navStyles.dropdownMobileSection}>
                        <div>
                          <Link href="/demo" underline="hover">
                            <span
                              style={{
                                fontSize: "18px",
                                color: mainTheme.palette.secondary.main,
                                textTransform: "uppercase",
                                letterSpacing: "0.10em",
                              }}
                            >
                              Request Demo
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className={navStyles.dropdownMobileSection}>
                        <div className={navStyles.dropdownMobileHeader}>Solutions</div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/idx-broker">IDX Broker</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="ixact-contact">IXACT Contact</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="morris-marketing-group">Morris Marketing Group</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="outbound-engine">OutboundEngine</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="aiva">Aiva</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="voicepad">VoicePad</Link>
                        </div>
                      </div>
                      <div className={navStyles.dropdownMobileSection}>
                        <div className={navStyles.dropdownMobileHeader}>Who We Serve</div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/agent-solutions">Agents</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/broker-and-team-solutions">Brokers &amp; Teams</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/developer-partners">Developer Partners</Link>
                        </div>
                      </div>
                      <div className={navStyles.dropdownMobileSection}>
                        <div className={navStyles.dropdownMobileHeader}>Resources</div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/blog">Blog</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/educational_guides">Educational Guides</Link>
                        </div>
                        <div className={navStyles.dropdownMenuLink}>
                          <Link href="/ourclients">Testimonials</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={navStyles.navigationDesktopNav}>
            <div className={navStyles.navigationBottomContainer}>
              <div className={navStyles.navigationMainLogo}>
                <Link href="/">
                  <Image src="/headerLogo.svg" alt="Elm Street Logo" width={197} height={45} />
                </Link>
              </div>
              <div className={`${activeMenu == "solutions" ? navStyles.dropdownActive : navStyles.dropdown}`}>
                <button
                  className={navStyles.dropdownMainLink}
                  onClick={handleOpen}
                  onMouseOver={handleOpen}
                  id="solutions"
                >
                  Solutions
                </button>
                <div className={navStyles.dropdownMenu} onMouseLeave={handleClose}>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/idx-broker">IDX Broker</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="ixact-contact">IXACT Contact</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="morris-marketing-group">Morris Marketing Group</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="outbound-engine">OutboundEngine</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="aiva">Aiva</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="voicepad">VoicePad</Link>
                  </div>
                </div>
              </div>
              <div className={`${activeMenu == "whoweserve" ? navStyles.dropdownActive : navStyles.dropdown}`}>
                <button
                  className={navStyles.dropdownMainLink}
                  onClick={handleOpen}
                  onMouseOver={handleOpen}
                  id="whoweserve"
                >
                  who we serve
                </button>
                <div className={navStyles.dropdownMenu} onMouseLeave={handleClose}>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/agent-solutions">Agents</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/broker-and-team-solutions">Brokers &amp; Teams</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/developer-partners">Developer Partners</Link>
                  </div>
                </div>
              </div>
              <div className={`${activeMenu == "resources" ? navStyles.dropdownActive : navStyles.dropdown}`}>
                <button
                  className={navStyles.dropdownMainLink}
                  onClick={handleOpen}
                  onMouseOver={handleOpen}
                  id="resources"
                >
                  resources
                </button>
                <div className={navStyles.dropdownMenu} onMouseLeave={handleClose} style={{ marginLeft: "-70px" }}>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/blog">Blog</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/educational_guides">Educational Guides</Link>
                  </div>
                  <div className={navStyles.dropdownMenuLink}>
                    <Link href="/ourclients">Testimonials</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default MainHeader;
